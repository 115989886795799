import { config, cdp } from '../index'

const state = () => {
  return {
    config: null
  }
}

const actions = {
  async getConfig({ commit }) {
    try {
      const response = await config.get()
      commit('SET_CONFIG', response.data)
      return response.data
    } catch (e) {
      console.error(e)
    }
  },

  async unsubscribe(_, params) {
    try {
      const response = await cdp.post('/v1/unsubscribe', params)
      return response?.data
    } catch (e) {
      return e
    }
  }
}

// mutations
const mutations = {
  ['SET_CONFIG'](state, config) {
    state.config = config
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
