import { createRouter, createWebHistory } from 'vue-router'
import { useStore } from '@/store'
import i18n from '@/i18n'

const store = useStore()

const routes = [
  {
    path: '/',
    name: 'stub',
    component: () => import('../views/MainView.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    component: () => import('../views/MainView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async to => {
  const { config } = await store.dispatch('main/getConfig')

  setFavicon(config.head_favicon)
  setPropertyColors(config.colors)
  seti18nParams(config.local, to.query)
  setOtherProperty(config)
})

const seti18nParams = (local, query) => {
  const locales = JSON.parse(local || '{}')
  const countryCodes = Object.keys(locales)

  i18n.global.fallbackLocale.value = countryCodes[0]

  Object.entries(locales).forEach(([countryCode, messages]) => {
    i18n.global.setLocaleMessage(countryCode, messages)
  })

  // Language detection by get parameter (lang=en)
  if (query.lang) {
    if (countryCodes.includes(query.lang)) {
      i18n.global.locale.value = query.lang
      return
    }
  }

  // Language detection by browser language
  let browserLanguage = navigator.language || navigator.userLanguage
  browserLanguage = browserLanguage.split('-')[0]

  if (countryCodes.includes(browserLanguage)) {
    i18n.global.locale.value = browserLanguage
    return
  }

  // Setting the default language first in the locale object
  i18n.global.locale.value = countryCodes[0]
}

const setFavicon = faviconUrl => {
  var link = document.querySelector("link[rel*='icon']")
  link.href = faviconUrl
}

const setPropertyColors = configColors => {
  const colors = JSON.parse(configColors) || {}

  setDefaultThemeColor(colors?.background)

  Object.entries(colors).forEach(([key, color]) => {
    document.body.style.setProperty(`--${key}`, color)
  })
}

const setDefaultThemeColor = color => {
  var metaThemeColor = document.querySelector("meta[name*='theme-color']")
  metaThemeColor.content = color
}

const setOtherProperty = config => {
  document.body.style.setProperty('--background_pic', `url(${config.background_pic})`)
}

export default router
